import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data }) => (
  <Layout>
    <SEO title="Strategies" />

    <h1>Strategies</h1>

    {data.allMarkdownRemark.edges.map(({ node }, index) => (
      <div key={index}>
        <Link to={node.frontmatter.slug}>{node.frontmatter.title}</Link>
      </div>
    ))}
  </Layout>
);

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;
